// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableFixHead {
    overflow: auto;
    height: 100px;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.divScroll {
    overflow: auto;
    max-height: 300px;
}

.divTradingView {
    height: 500px;
}

.cardDark{
    background-color: #363c4e;
}`, "",{"version":3,"sources":["webpack://./src/private/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,UAAU;AACd;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".tableFixHead {\n    overflow: auto;\n    height: 100px;\n}\n\n.tableFixHead thead th {\n    position: sticky;\n    top: 0;\n    z-index: 1;\n}\n\n.divScroll {\n    overflow: auto;\n    max-height: 300px;\n}\n\n.divTradingView {\n    height: 500px;\n}\n\n.cardDark{\n    background-color: #363c4e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
